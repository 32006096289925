import { formatDateLong } from '@common/utils/date';
import { listify } from '@common/utils/listify';
import { EVENT_DESCRIPTION_DELIMITER } from '@data/constants';
import { DiceEvent, DiceTrack } from '@interfaces';

export const URL_RESERVATION =
	'https://www.sevenrooms.com/reservations/elsewherebk';
const DICE_FULL_VENUE = 'Elsewhere, Brooklyn';

const REGEX_EVENTBRITE = /eventbrite/i;

export const getEventBriteUrl = (event: DiceEvent) => {
	return event.links?.find(({ name }) => REGEX_EVENTBRITE.test(name));
};

export const createEventSlug = ({
	intId,
	permName,
	destinationEventPermName,
}: DiceEvent) => {
	if (permName) {
		return `${intId}-${permName}`;
	}

	if (destinationEventPermName) {
		return `${intId}-${destinationEventPermName}`;
	}

	return String(intId);
};

export const getDiceTicketUrl = ({
	hash,
	permName,
	externalUrl,
}: DiceEvent) => {
	if (hash && permName) {
		return `https://dice.fm/event/${hash}-${permName}`;
	}

	return externalUrl;
};

export const getTicketUrl = (
	event: DiceEvent,
	diceAccessCode: string | null = null,
) => {
	let ticketUrl: string | null;

	const eventBriteLink = getEventBriteUrl(event);

	if (eventBriteLink) {
		ticketUrl = eventBriteLink.url;
	} else {
		ticketUrl = getDiceTicketUrl(event);

		if (diceAccessCode && ticketUrl) {
			const url = new URL(ticketUrl);
			const params = new URLSearchParams(url.search);
			params.append('code', diceAccessCode);
			url.search = params.toString();

			ticketUrl = url.toString();
		}
	}

	return ticketUrl;
};

type GetEventDescriptionParams = {
	event: DiceEvent;
	hideAgeLimit?: boolean;
	hidePresentedBy?: boolean;
};

export const getEventDescription = ({
	event,
	hideAgeLimit = false,
	hidePresentedBy = false,
}: GetEventDescriptionParams): string | undefined => {
	const { description, ageLimit, presentedBy } = event;
	const hasDelimiter = description.includes(EVENT_DESCRIPTION_DELIMITER);
	const eventDescription = [];

	eventDescription.push(
		hasDelimiter
			? `${description.split(EVENT_DESCRIPTION_DELIMITER)[0]}\n\n`
			: null,
	);

	if (ageLimit && !hideAgeLimit) {
		eventDescription.push(`${ageLimit}\n`);
	}

	if (presentedBy && !hidePresentedBy) {
		eventDescription.push(presentedBy);
	}

	return eventDescription.filter((part) => !!part).join('');
};

export const getRepresentativeTicketPrice = ({
	price,
	ticketTypes,
}: DiceEvent) => {
	if (ticketTypes.length) {
		// Get the general admission ticket and return the face value price
		const generalAdmission = ticketTypes.find(({ name }) => {
			return name.toLowerCase() === 'general admission';
		});

		if (generalAdmission) {
			return generalAdmission.price.faceValue;
		}
	}

	if (price) {
		return price;
	}

	return undefined;
};

export const getIsLiveStreamEvent = (event: DiceEvent): boolean => {
	const REGEX_STREAM = /Stream via Elsewhere/;
	return REGEX_STREAM.test(event.venue);
};

export const stripElsewhereFromVenue = (venue: string): string => {
	return venue.replace(/^Elsewhere - /, '');
};

export const sanitizeVenueName = (venue: string): string => {
	if (venue === DICE_FULL_VENUE) {
		return 'Full Venue';
	}

	return stripElsewhereFromVenue(venue);
};

export const getEventType = (tags: string[]): string => {
	const typeTag = tags.find((tag) => /music:/.test(tag));
	const [, type] = typeTag?.split(':') || [];

	switch (type) {
		case 'dj':
			return 'Club';
		case 'gig':
			return 'Live';
		default:
			return '';
	}
};

export const getTrackPreview = ({
	spotifyTracks,
	appleMusicTracks,
}: DiceEvent): DiceTrack | undefined => {
	const spotifyTrack = spotifyTracks.find(({ previewUrl }) => {
		return previewUrl !== null;
	});

	const appleMusicTrack = appleMusicTracks.find(({ previewUrl }) => {
		return previewUrl !== null;
	});

	return spotifyTrack || appleMusicTrack;
};

export const getEarliestTime = ({ lineup }: DiceEvent): string | null => {
	// For display purposes we require the earliest time. We assume the lineup contains: [doorsOpen, start, end]
	const earliestLineupEntry = lineup?.find(({ time }) => time.length > 0);

	if (earliestLineupEntry) {
		return earliestLineupEntry.time;
	}

	return null;
};

export const getIsEventSoldOut = (event: DiceEvent) => {
	// The linkout event does not contain any ticket information
	if (event.type === 'linkout') return false;

	// using event.soldOut seems to be unreliable as this is still
	// false even in the case that all tickets are soldOut
	return (
		event.ticketTypes.every(({ soldOut }) => soldOut === true) &&
		!getEventBriteUrl(event)
	);
};

export const getHeadlinerArtist = (event: DiceEvent) => {
	const headlinerArtist = event.detailedArtists.find((artist) => {
		return artist.headliner;
	});

	return headlinerArtist?.name;
};

export const sortDetailedArtists = (
	detailedArtists: DiceEvent['detailedArtists'],
) => {
	return detailedArtists.sort((b, a) => {
		return Number(a.headliner) - Number(b.headliner);
	});
};

// Gather some data to build an event description out of
export const getEventMetaDescription = (event: DiceEvent) => {
	const venues = event.venues.map(({ name }) => sanitizeVenueName(name));
	const venueString = listify(venues);
	const date = formatDateLong(event.date);
	const earliestTime = getEarliestTime(event);
	// Sometimes headliners are not filled out so we need some fallback here
	const artist = getHeadlinerArtist(event) || event.artists[0] || event.name;

	const eventDescription = `${artist} performing on ${date} at ${venueString} from ${earliestTime} at Elsewhere, Brooklyn NY`;

	return eventDescription;
};

export const formatAddToCalenderDate = (date: string) => {
	// removes miliseconds, replaces colons and dashes
	return date.replace(/[\W_]+/g, '');
};

interface GetAddToCalendarLink {
	type: 'google' | 'apple';
	event: DiceEvent;
}

export const getAddToCalendarLink = ({ type, event }: GetAddToCalendarLink) => {
	switch (type) {
		case 'google': {
			// https://github.com/InteractionDesignFoundation/add-event-to-calendar-docs/blob/main/services/google.md
			const addToCalenderUrl = new URL(
				'https://www.google.com/calendar/render',
			);

			const startDate = formatAddToCalenderDate(event.date);
			const endDate = formatAddToCalenderDate(event.dateEnd);

			const eventUrl = `${window.location.protocol}//${
				window.location.host
			}/events/${createEventSlug(event)}`;

			const searchParams = [
				['action', 'TEMPLATE'],
				['text', event.name],
				['details', eventUrl],
				['location', `Elsewhere, ${event.address}`],
				['dates', `${startDate}/${endDate}`],
			];

			searchParams.forEach(([key, value]) => {
				addToCalenderUrl.searchParams.append(key, value);
			});

			return addToCalenderUrl.href;
		}
		default: {
			return `/api/add-to-calendar?event_id=${event.id}`;
		}
	}
};
